<template>
    <ValidationObserver ref="form">
        <form class="row-between" @submit.prevent="validate">

            <div class="contenedor-input -middle">
                <label for="">Nombre</label>
                <ValidationProvider name="nombre" rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                    <input :class="classes" v-model="data.name" type="text" placeholder="Nombre" v-bind="ariaInput">
                    <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>

            <div class="contenedor-input -middle">
                <label for="">Primer apellido</label>
                <ValidationProvider name="apellidos" rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                    <input :class="classes" v-model="data.surname_1" type="text" placeholder="Primer apellido"
                        v-bind="ariaInput">
                    <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>

            <div class="contenedor-input -middle">
                <label for="">Segundo apellido<span></span></label>
                <input v-model="data.surname_2" type="text" placeholder="Segundo apellido">
            </div>

            <div class="contenedor-input -middle">
                <label for="">Email</label>
                <ValidationProvider name="email" rules="required|email" v-slot="{ errors,ariaInput,ariaMsg ,
            classes}">
                    <input :class="classes" v-model="data.email" type="text" placeholder="Email" v-bind="ariaInput">
                    <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>

            <div class="contenedor-input">
                <label for="">Tipo de contacto</label>
                <ValidationProvider name="type" rules="required" v-slot="{ errors,ariaInput,ariaMsg ,
                classes}">
                    <select :class="classes" name="type" id="" v-model="data.type">
                        <option value="">Selecciona tipo de contacto</option>
                        <option value="0">Contacto general</option>
                        <option value="1">Quiero colaborar como mentor/a ponente en próximos talleres de Aula Mentoring</option>
                    </select>
                    <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>

            <div class="contenedor-input -middle" v-if="data.type == 1">
                <label for="">Estudios cursados en UC3M</label>
                <ValidationProvider name="estudios" rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                    <input :class="classes" v-model="data.study" type="text" placeholder="Estudios" v-bind="ariaInput">
                    <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>

            <div class="contenedor-input -middle" v-if="data.type == 1">
                <label for="">Teléfono de contacto</label>
                <ValidationProvider name="teléfono" rules="required" v-slot="{ errors,ariaInput,ariaMsg ,
            classes}">
                    <input :class="classes" v-model="data.phone" type="text" placeholder="Teléfono" v-bind="ariaInput">
                    <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>

            <div class="contenedor-input -middle" v-if="data.type == 1">
                <label for="">Perfil LinkedIn</label>
                <input :class="classes" v-model="data.linkedin" type="text" placeholder="Url" v-bind="ariaInput">
            </div>

            <div class="contenedor-input -middle" v-if="data.type == 1">
                <label for="">Temática de la sesión</label>
                <ValidationProvider name="tematica" rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                    <input :class="classes" v-model="data.thematic" type="text" placeholder="Temática"
                        v-bind="ariaInput">
                    <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>

            <div class="contenedor-input" v-if="data.type == 0">
                <ValidationProvider name="asunto" rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                    <label for="">Asunto</label>
                    <input :class="classes" type="text" name="asunto" v-model="data.subject">
                    <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>

            <div class="contenedor-input">
                <label for="">Mensaje</label>
                <ValidationProvider name="message" rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                    <textarea :class="classes" name="" id="" cols="30" rows="10" v-model="data.message"></textarea>
                    <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>



            <div class="contenedor-check">
                <ValidationProvider :rules="{ required: { allowFalse: false } }" v-slot="{ errors,ariaInput,ariaMsg, classes }">
                    <input :class="classes" id="politica" name="politica" v-model="data.politica" type="checkbox"
                        placeholder="politica" v-bind="ariaInput">
                    <label for="politica">He leído y acepto la <a
                            href="https://www.uc3m.es/inicio/informacion-legal/politica-privacidad" target="_blank">
                            Política de privacidad.
                        </a></label>
                    <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>

            <div class="texto-legal -black">
                <h2>Información básica sobre protección de datos.</h2>
                <p>RESPONSABLE DEL TRATAMIENTO. Universidad Carlos III de Madrid.</p>
                <p>IDENTIFICACIÓN DEL TRATAMIENTO, CONTACTA</p>
                <p>
                    <strong>FINALIDAD.</strong> Dar ayuda y soporte en la Universidad mediante la generación de foros de
                    ayuda, y la
                    presentación de incidencias, dudas, quejas o sugerencias. Punto de contacto con la Universidad.
                </p>
                <p>
                    <strong>EJERCICIO DE DERECHOS.</strong> Podrá ejercer sus derechos de acceso, rectificación,
                    supresión,
                    limitación,
                    portabilidad y oposición a su tratamiento, mediante el envío de un correo a la siguiente dirección:
                    dpd@uc3m.es
                </p>
                <p>
                    <strong>INFORMACIÓN ADICIONAL</strong>: Puede consultar la información adicional y detallada sobre
                    nuestra Política de
                    Privacidad en <a target="_blank"
                        href="https://www.uc3m.es/protecciondedatos">https://www.uc3m.es/protecciondedatos</a>
                </p>
            </div>

            <button class="btn -skull" :loading="loading" v-if="!enviado">Enviar</button>
            <h3 v-if="enviado">Enviado</h3>

        </form>

    </ValidationObserver>

</template>

<script>

    import { mapActions } from 'vuex';

    export default {
        name: 'formulario-contacto',
        mounted() {
            if (this.$route.params.id == 'aula-mentoring') {
                this.data.type = 2;
            }
        },
        data() {
            return {
                data: {
                    email: '',
                    name: '',
                    phone: '',
                    surname_1: '',
                    surname_2: '',
                    subject: '',
                    message: '',
                    type: '',
                    linkedin: '',
                    thematic: '',
                    study: '',
                    source: 0
                },
                loading: false,
                enviado: false
            }
        },
        methods: {
            ...mapActions(['sendContacto']),
            validate() {
                this.$refs.form.validate()
                    .then(itsOk => {
                        if (itsOk) {
                            this.loading = true;
                            this.data.surnames = this.data.surname_1 + " " + this.data.surname_2;
                            this.sendContacto(this.data).then(r => {
                                this.loading = false;
                                if (r) {
                                    this.reset();
                                }
                            })
                        }
                    });
            },
            reset() {
                this.$refs.form.reset();
                this.enviado = true;
                this.data = {
                    email: '',
                    name: '',
                    phone: '',
                    subject: '',
                    message: '',
                    type: '',
                    linkedin: '',
                    thematic: '',
                    study: ''
                }

                setTimeout(() => {
                    this.enviado = false;
                }, 2000)

            }
        }
    }
</script>